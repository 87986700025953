import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Axios from 'axios';
import Carousel from 'react-elastic-carousel';

import { TransitionGroup, CSSTransition } from "react-transition-group";
import UnderPopup from './popup/underpopup';
import InfoConent from './popup/infoConent';
import ActionContent from './popup/actionContent';
import { Base64 } from 'js-base64';
import { Tooltip, Zoom } from '@material-ui/core';

let lang = require('../lang/en').default;


if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
    localStorage.setItem('lang', 'En');
    lang = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang = require('../lang/en').default;
    }
    else {
        lang = require('../lang/ar').default;
    }
}

class Search extends Component {
    state = {
        items: [],
        type: 'songs',
        popupshow: [],
        tags: [],
        showPopup: false,
        showPopup2: false,
        favSt: '',
        playlistName: '',
        selectType: 'songs',
        songs: [],
        shows: [],
        stories: [],
        songsSelected: [],
        showsSelected: [],
        storiesSelected: [],
        keyword: '',
        showPopup: false,
        textPopup: '',
        popupSub: false,
        lang: lang
    }
    saveUpdate() {

        var arr = JSON.parse(localStorage.getItem('token'));
        let formData = new FormData();
        formData.append('access_token', arr.access_token);
        formData.append('name', this.state.playlistName);
        let arrayProfile = JSON.parse(localStorage.getItem('Profiles'));
        for (var z = 0; z < arrayProfile.newarray.length; z++) {
            if (arrayProfile.newarray[z].mainProfile) {
                formData.append('profileId', arrayProfile.newarray[z]._id);
            }
        }

        let songs = this.state.songsSelected.map((item) => item._id);
        let shows = this.state.showsSelected.map((item) => item._id);
        let stories = this.state.storiesSelected.map((item) => item._id);
        formData.append('songslist', JSON.stringify(songs));
        formData.append('showslist', JSON.stringify(shows));
        formData.append('storieslist', JSON.stringify(stories));

        formData.append('lang', localStorage.getItem('lang'));

        formData.append('playlist_id', this.props.match.params.id);

        //console.log("Create/Edit playlist = " + JSON.stringify(formData))

        Axios.post(process.env.REACT_APP_BACKEND_URL + '/app/playList/edit',
            formData,
        ).then(response => {
            this.setState({
                showPopup: true,
                textPopup: response.data.msg
            });

            setTimeout(function () {
                this.setState({
                    showPopup: false,
                    textPopup: ''
                });

                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'playlist/' + this.props.match.params.id);
            }.bind(this), 1000);
        })
    }
    changeType = (type) => {
        var arr = JSON.parse(localStorage.getItem('token'));
        this.setState({
            selectType: type,
            items: []
        })



        let formData = new FormData();
        formData.append('profile_id', 'guest');
        formData.append('typeOFContent', type);

        formData.append('lang', localStorage.getItem('lang'));


        formData.append('keyword', this.state.keyword);

        formData.append('genreID', '');

        Axios.post(process.env.REACT_APP_BACKEND_URL + '/app/search/getResult',
            formData,
        ).then(response3 => {
            this.setState({
                items: response3.data[type]
            })
        })


    }
    componentDidMount() {
        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/accesscode";
            let formData = new FormData();
            formData.append("access_token", arr.access_token);
            Axios.post(url,
                formData,
            ).then(response => {
                if (response.data.data == undefined) {
                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                }
                else {

                    let formData2 = new FormData();
                    formData2.append('access_token', arr.access_token);


                    formData2.append('playListId', this.props.match.params.id);

                    formData2.append('lang', localStorage.getItem('lang'));

                    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/getContent",
                        formData2,
                    ).then(response2 => {

                        let formData = new FormData();
                        formData.append('profile_id', 'guest');
                        formData.append('typeOFContent', this.state.selectType);

                        formData.append('lang', localStorage.getItem('lang'));


                        formData.append('keyword', this.state.keyword);

                        formData.append('genreID', '');


                        Axios.post(process.env.REACT_APP_BACKEND_URL + '/app/search/getResult',
                            formData,
                        ).then(response3 => {
                            this.setState({
                                items: response3.data[this.state.selectType],
                                playlistName: response2.data.playListName,
                                songsSelected: response2.data.allarray[0].songslist,
                                storiesSelected: response2.data.allarray[2].storieslist,
                                showsSelected: response2.data.allarray[1].showslist
                            })
                        })


                    })




                }
            })

        }
    }

    clikeState(id) {
        let select = false;
        if (this.state.selectType == 'songs') {
            this.state.songsSelected.map((item) => {
                if (item._id === id) {
                    console.log(id)
                    select = true
                }
            })
        }
        else if (this.state.selectType == 'shows') {
            this.state.showsSelected.map((item) => {
                if (item._id === id) {
                    console.log(id)
                    select = true
                }
            })
        }
        else if (this.state.selectType == 'stories') {
            this.state.storiesSelected.map((item) => {
                if (item._id === id) {
                    console.log(id)
                    select = true
                }
            })
        }
        return select
    }
    insertitemPlaylist(id) {
        let select = false;
        this.state[this.state.selectType + 'Selected'].map((item) => {
            if (item._id == id._id) {
                select = true
            }
        })

        if (!select) {
            this.setState({
                [this.state.selectType + 'Selected']: this.state[this.state.selectType + 'Selected'].concat([id])
            })


            console.log(this.state[this.state.selectType + 'Selected'])
        } else {
            this.setState({
                [this.state.selectType + 'Selected']: this.state[this.state.selectType + 'Selected'].filter(item => item._id != id._id)
            })
            console.log(this.state[this.state.selectType + 'Selected'])

        }


    }
    handleChange = (e, name) => {
        this.setState({
            [name]: e.target.value,
        });

        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/search/getResult";
        let formData = new FormData();
        formData.append('profile_id', 'guest');
        formData.append('typeOFContent', this.state.selectType);

        formData.append('lang', localStorage.getItem('lang'));


        formData.append('keyword', e.target.value);

        formData.append('genreID', '');


        Axios.post(url,
            formData,
        ).then(response2 => {
            this.setState({
                items: response2.data[this.state.selectType]
            })
        })
    };
    render() {

        return (
            <div>
                <section id="Search" >
                    <div className="popupSub" style={{ opacity: this.state.popupSub ? 1 : 0, visibility: this.state.popupSub ? 'visible' : 'hidden', transition: 'all 0.7s' }}>
                        <div className="colsebtn" onClick={() => this.setState({ popupSub: false })}>X</div>
                        <div className="boxPopup">

                            <img src={require("../assets/img/Lock icon.png")} />
                            <div className="textPopup">{this.state.lang['SubscribeNowPopup'].Upgrade}</div>
                            <div className="btns">
                                <a className="btn1"
                                    onClick={() => {
                                        this.props.history.push(process.env.REACT_APP_FRONT_URL + 'subscription');
                                    }}
                                >{this.state.lang['SubscribeNowPopup'].UpgradeBTN}</a>
                            </div>
                        </div>

                    </div>
                    <UnderPopup show={this.state.showPopup} text={this.state.textPopup} />
                    <div className="header">
                        <div className="leftSec">
                            <img className="img pointer" src="https://royakids.tv/websitedemo/logo.png" onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} />

                            <img id="backbtn" onClick={() => {
                                this.props.history.goBack()
                            }} src={require("../assets/img/Group 4877.png")} />
                            <div className="SearchTitle">{this.state.playlistName}</div>
                        </div>
                        <div className="centerSec">

                        </div>
                        <div className="rightSec">
                            <div>
                                <a className="btn1" onClick={() => {
                                    this.saveUpdate()
                                }}>{
                                    this.state.lang['PlaylistScreen'].saveButtonLAbel}
                                </a>
                                <Tooltip TransitionComponent={Zoom} title={localStorage.getItem('lang') === 'Ar' ? "حسابي" : "My account"} aria-label={localStorage.getItem('lang') == 'Ar' ? "حسابي" : "My account"} arrow>
                                    <div>
                                        <img src={localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' ? localStorage.getItem('minImg') ? Base64.decode(localStorage.getItem('minImg')) : '' : require("../assets/img/Group 4967.png") : ''} onClick={() => {
                                            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'parents');
                                        }} />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                    </div>
                    <div className="bodyHome">
                        <div className="rowTop">
                            <div className="countLeft">
                                <img src={require("../assets/img/Group 2240.png")} />
                                <div className="itemCounter itemCounterNoBorder" key="Songs">
                                    {this.state.songsSelected.length}
                                    <br></br>
                                    {this.state.lang['PlaylistScreen'].labelSongsCount}
                                </div>
                                <div className="itemCounter" key="Stories">
                                    {this.state.storiesSelected.length}
                                    <br></br>
                                    {this.state.lang['PlaylistScreen'].labelStoriesCount}
                                </div>
                                <div className="itemCounter" key="Shows">
                                    {this.state.showsSelected.length}
                                    <br></br>
                                    {this.state.lang['PlaylistScreen'].labelShowsCount}
                                </div>
                            </div>
                            <div className="centerAdd">
                                <div className={this.state.selectType == 'songs' ? "addBtn activeBtn" : "addBtn"} onClick={() => this.changeType('songs')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="61.858" height="61.858" viewBox="0 0 61.858 61.858">
                                        <g id="Group_5019" data-name="Group 5019" transform="translate(0)">
                                            <g id="Ellipse_391" data-name="Ellipse 391" transform="translate(30.929) rotate(45)" fill="none" stroke="#7c68dc" stroke-width="4">
                                                <circle cx="21.87" cy="21.87" r="21.87" stroke="none" />
                                                <circle cx="21.87" cy="21.87" r="19.87" fill="none" />
                                            </g>
                                            <g id="Group_1221" data-name="Group 1221" transform="translate(30.929 22.739) rotate(45)">
                                                <line id="Line_33" data-name="Line 33" x2="16.386" transform="translate(0 0) rotate(45)" fill="none" stroke="#7c68dc" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                                                <line id="Line_32" data-name="Line 32" y2="16.385" transform="translate(11.587 0) rotate(45)" fill="none" stroke="#7c68dc" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                                            </g>
                                        </g>
                                    </svg>
                                    {this.state.lang['PlaylistScreen'].buttonTabAddSongsLabel}</div>
                                <div className={this.state.selectType == 'stories' ? "addBtn activeBtn" : "addBtn"} onClick={() => this.changeType('stories')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="61.858" height="61.858" viewBox="0 0 61.858 61.858">
                                        <g id="Group_5019" data-name="Group 5019" transform="translate(0)">
                                            <g id="Ellipse_391" data-name="Ellipse 391" transform="translate(30.929) rotate(45)" fill="none" stroke="#7c68dc" stroke-width="4">
                                                <circle cx="21.87" cy="21.87" r="21.87" stroke="none" />
                                                <circle cx="21.87" cy="21.87" r="19.87" fill="none" />
                                            </g>
                                            <g id="Group_1221" data-name="Group 1221" transform="translate(30.929 22.739) rotate(45)">
                                                <line id="Line_33" data-name="Line 33" x2="16.386" transform="translate(0 0) rotate(45)" fill="none" stroke="#7c68dc" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                                                <line id="Line_32" data-name="Line 32" y2="16.385" transform="translate(11.587 0) rotate(45)" fill="none" stroke="#7c68dc" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                                            </g>
                                        </g>
                                    </svg>
                                    {this.state.lang['PlaylistScreen'].buttonTabAddStoriesLabel}</div>
                                <div className={this.state.selectType == 'shows' ? "addBtn activeBtn" : "addBtn"} onClick={() => this.changeType('shows')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="61.858" height="61.858" viewBox="0 0 61.858 61.858">
                                        <g id="Group_5019" data-name="Group 5019" transform="translate(0)">
                                            <g id="Ellipse_391" data-name="Ellipse 391" transform="translate(30.929) rotate(45)" fill="none" stroke="#7c68dc" stroke-width="4">
                                                <circle cx="21.87" cy="21.87" r="21.87" stroke="none" />
                                                <circle cx="21.87" cy="21.87" r="19.87" fill="none" />
                                            </g>
                                            <g id="Group_1221" data-name="Group 1221" transform="translate(30.929 22.739) rotate(45)">
                                                <line id="Line_33" data-name="Line 33" x2="16.386" transform="translate(0 0) rotate(45)" fill="none" stroke="#7c68dc" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                                                <line id="Line_32" data-name="Line 32" y2="16.385" transform="translate(11.587 0) rotate(45)" fill="none" stroke="#7c68dc" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                                            </g>
                                        </g>
                                    </svg>
                                    {this.state.lang['PlaylistScreen'].buttonTabAddShowsLabel}</div>
                            </div>
                            <div className="search">
                                <input type="text" id="input-search" class="input-text input-text--search" placeholder={this.state.lang['PlaylistScreen'].textInputSearchLabel}
                                    value={this.state.keyword}
                                    onChange={(e) => {
                                        this.handleChange(e, "keyword");
                                    }}
                                />
                            </div>
                        </div>
                        <div className="ResultPlaylist">

                            {
                                this.state.items.length != 0 ?
                                    this.state.items
                                        .map(video =>
                                            <div>
                                                <div key={video._id} className={this.clikeState(video._id) ? 'item2' : 'item'}>

                                                    <div className="lockIcon" style={{ display: !video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3') ? 'none' : '' }}></div>

                                                    <div className="gotocontent"
                                                        onClick={() => {
                                                            if (!video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3'))
                                                                this.insertitemPlaylist(video)
                                                            else
                                                                this.setState({
                                                                    popupSub: true,
                                                                    showPopup2: false,
                                                                    showPopup: false
                                                                })
                                                        }}
                                                    ></div>
                                                    <img className="mainImg" src={process.env.REACT_APP_BACKEND_URL + "/public/" + video.coverImg}></img>
                                                    {/* <div className="titleitem">{video.title}</div> */}
                                                </div>

                                            </div>





                                        )
                                    :
                                    this.state.lang['Shared'].noContentsAvailable2
                            }
                        </div>
                    </div>
                </section>


            </div >
        );
    }
}

export default Search;