export default {
    createAccountScreen: {
        title: 'Create an Account',
        inputEmail: 'Your email address',
        inputPassword: 'Your password',
        buttonNext: 'Continue',
        or: 'OR',
        signuptxt: 'Already have an account?',
        signup: 'Log In',
    },
    authenticationScreen: {
        title: 'Welcome to Roya Kids',
        buttonCreateAccount: 'Create an account',
        buttonLogin: 'Log in',
        buttonGuestUser: 'Use Free Version',
    },
    LoginScreen: {
        title: 'Log in',
        inputEmail: 'Your email address',
        inputPassword: 'Your password',
        buttonNext: 'Continue',
        buttonForgetPassword: 'Forget password?',
        or: 'Or',
        signuptxt: 'Don`t have an account?',
        signup: 'Sign up',
    },
    ResetPasswordScreen: {
        title: 'Reset your password',
        inputEmail: 'Your email address',
        instruction: 'We will send an email to the registered address',
        buttonNext: 'Reset your password',
        subscribebtn: 'Subscribe',
        error: 'This user does not exist',
        error1: 'Your balance is insufficient',
        error2: 'You have an active subscription on the same phone number',
        error3: 'incomplete process',
        USER_DECLINED_PURCHASE_DURING_CONFIRMATION: "USER DECLINED PURCHASE DURING CONFIRMATION",
        USER_IS_BLOCKED: "USER IS BLOCKED",
        USER_NOT_ACTIVE: "USER NOT ACTIVE",
        USER_IS_BLACKLISTED: "USER IS BLACKLISTED",
        EXPIRED_OTP: "EXPIRED OTP",
        USER_BLOCKED_BY_PROVIDER: "USER BLOCKED BY PROVIDER",
        succ: 'Successfully subscribed!',
        quit: 'Home Page',
    },
    NavigationButtonsMain: {
        button1: 'Songs',
        button2: 'Stories',
        button3: 'Shows',
        button4: 'Games',
    },
    NavigationButtonsInner: {
        button1: 'All',
        button2: 'Songs',
        button3: 'Stories',
        button4: 'Shows',
        button5: 'Games',
    },

    RegisterationEnterPhoneNumberScreen: {
        title: "Your phone number",
        buttonNextLabel: "Continue",
        instructionsText: "You will get 6 digit verification code via sms.",
        buttonSendEmailLabel: "Or send email",
    },
    RegisterationPhoneNumberVerificationScreen: {
        title: "Phone number verification",
        titleEmail: "Email verifivation",
        buttonNextLabel: "Continue",
        instructionsText: "Didn't get the code?",
        buttonResendCodeLabel: "Resend code",
        enterPhone: "Enter a phone number",
    },
    RegistrationChildFirstNameScreen: {
        titlePage: "Create your child’s profile",
        title: "Your child first name",
        inputChildNameLabel: "Enter your child first name",
        buttonContinueLabel: "Continue",

    },
    RegistrationChildDateOfBirthScreen: {
        title: "Your child date of birth",
        inputYearLabel: "Year",
        inputMonthLabel: "Month",
        inputDayLabel: "Day",
        buttonContinueLabel: "Continue",
    },
    RegistrationChildGenderScreen: {
        title: 'Your child gender',
        labelMale: 'Boy',
        labelFemale: 'Girl',
        buttonContinueLabel: 'Continue',
    },
    RegistrationChildProfileImageScreen: {
        title: 'Upload a profile image',
        titleSub: 'Or select an avatar',
        buttonContinueLabel: 'Continue',
    },


    InterestsStoriesScreen: {
        title: 'Choose stories interests',
        buttonSkip: 'Skip',
        buttonNext: 'Continue',
        finish: 'finish'
    },
    InterestsSongsScreen: {
        title: 'Choose songs interests',
        buttonSkip: 'Skip',
        buttonNext: 'Next',
    },
    InterestsShowsScreen: {
        title: 'Choose shows interests',
        buttonSkip: 'Skip',
        buttonNext: 'Next',
    },


    ItemMenuPopup: {
        buttonAddToPlaylistLabel: 'Add to playlist',
        buttonDownloadLabel: 'Download this video',
        buttonDownloadedLabel: 'Downloaded',
        buttonAddToFavouriteLabel: 'Add to favorites',
        buttonRemoveFromFavouriteLabel: 'Remove from favorites',
        buttonDeleteContentLabel: 'Delete'
    },

    PlaylistsMainScreen: {
        buttonCreatePlayListLabel: 'Create playlist',
    },
    AddToPlaylistPopup: {
        title: 'Add to playlist',
        ButtonCreatePlaylistLabel: 'Create playlist',

    },
    CreatePlaylistNamePopup: {
        title: 'Create playlist',
        inputTxtLabel: 'Enter playlist name',
        ButtonCreateLabel: 'Create',

    },
    PlaylistScreen: {
        editButtonLabel: 'Edit',
        saveButtonLAbel: 'Save',
        textInputSearchLabel: 'Search',
        labelSongsCount: 'Songs',
        labelStoriesCount: 'Stories',
        labelShowsCount: 'Shows',
        buttonTabAddShowsLabel: 'Add shows',
        buttonTabAddStoriesLabel: 'Add stories',
        buttonTabAddSongsLabel: 'Add songs',
        all: 'All'
    },

    HeaderTitles: {
        titleMyFavorite: 'My favorites',
        titleMyDownloads: 'My downloads',
        titleMyPlaylists: 'My playlists',
        titleSettings: 'Settings',
        titleUpdateProfile: 'Update profile',
        titleHistory: 'Watch history'
    },

    SearchScreen: {
        textInputSearchLabel: 'Search',

    },

    SettingsScreen: {
        buttonTabProfilesLabel: 'Profiles',
        buttonTabSubscriptionsLabel: 'Subscription',
        buttonTabLanguagesLabel: 'Languages',
        buttonTabNotificationsLabel: 'Notifications',
        buttonTabPrivacyPoliciesLabel: 'Privacy policy',
        buttonTabTermsOfUseLabel: 'Terms of use'
    },

    profilesSwitchScreen: {
        title: 'To access content, please',
        buttonCreateAccountLabel: 'Create account',
        buttonLoginLabel: 'Log in',
        buttonLogoutLabel: 'Logout',
    },
    ManageProfilePopup: {
        switchProfileButtonLabel: 'Switch profile',
        manageProfileButtonLabel: 'Manage profile',
        deleteProfle: 'Delete Profile!'
    },

    LanguageSelectionScreen: {
        buttonSaveLabel: 'Save',
        buttonArabicLabel: 'Arabic',
        buttonEnglishLabel: 'English',
    },
    NotificationsScreen: {
        noContentsAvailableLabel: 'No notifications yet',
    },

    UpdateProfileScreen: {
        titleEditSettings: "Edit settings for",
        childNameLabel: 'Child Name',
        textInputChildNameLabel: 'Enter your child first name',
        dateOfBirthLabel: 'Child Date of Birth',
        textInputDobDayLabel: 'Day',
        textInputDobMonthLabel: 'Month',
        textInputDobYearLabel: 'Year',
        buttonMaleLabel: 'Boy',
        buttonFemaleLabel: 'Girl',
        genderLabel: 'Child Gender',
        buttonEditInterestsLabel: 'Edit Interests',
        buttonSaveLabel: 'Save',
        buttonCancelLabel: 'Cancel',

        labelHistory: "History",
        labelWatchHistory: "Watch history",
        labelClearHistory: "Clear history",
        labelScreenTime: "Screen time",
        labelDeleteProfile: "Delete this profile",
        btnDelete: "Delete",
    },
    DeleteProfilePopup: {
        message: 'Delete profile?',
        buttonOkLabel: 'Yes',
        buttonCancelLabel: 'No'
    },
    ClearWatchedHistoryPopup: {
        message: 'Clear history?',
        buttonOkLabel: 'Yes',
        buttonCancelLabel: 'No'
    },

    Shared: {
        noContentsAvailable: 'Coming soon',
        noHistoryContentsAvailable: 'No data available',
        noContentsAvailable2: 'No content available',
        buttonPrivacyPolicyLabel: 'Privacy policy',
        buttonTermsOfUseLabel: 'Terms & conditions',
        Seasons: 'Seasons'
    },

    SaveChangesPopup: {
        buttonOkLabel: 'Yes',
        buttonCancelLabel: 'No'
    },

    AlertMessages: {
        Warning: 'Warning',
        acceptedYear: 'This year cannot be accepted!',
        //Create account/Login
        emptyEmail: "Please insert your email!",
        invalidEmail: "Please enter valid email!",
        emptyPassword: "Please insert your password!",
        minPasswordLength: "Minimun password length is 6 characters!",
        loginFacebookCancelled: 'Login Cancelled!',
        somthingWentWrong: 'Somthing went wrong!',
        loginGoogleCancelled: 'Login Cancelled!',
        loginAlreadyProcessing: 'Log in is in progress already!',
        loginOutOfDate: 'play services not available or outdated',

        //Interests selection
        onEmptyInterests: 'Please chose your interests',

        //AvatarImage
        onEmptyAvatatImage: "Please select avatar or upload your child image",

        //Add for number
        onInvalidMobileNumber: "Please add valid phone number with country code",
        onEmptyMobileNumber: "Please add phone number",

        //Birth Date Screen
        onEmptyBirthDate: "Please select birth date",

        //Gender Selection
        onEmptyGender: "Please select gender",

        //Add Child name
        onEmptyChildName: "Please enter you child name",
        onChildNameTooShort: "Please enter valid name", //Min is two

        //Verification screen
        onInvalidCode: "Invalid code",
        onShortCode: "Please fill the verification code",
        onVerificationSuccess: 'Verification success',
        onCodeAlreadyRequested: 'Code already requested',
        onCodeRequestSent: 'Code request sent',

        //Create playlist popup
        onInvalidPlaylistName: 'Please enter valid playlist name',
        playlistNameAlreadyExists: 'Playlist name already exists!',

        //Playlist creation screen
        onNoneSelected: 'Please select at least one item',

        //Download manager
        onErrorDownloadContent: "Error downloading",
        onStartDownloadContent: "Start downloading",
        onDownloadSuccessfully: "Downloaded successfully!",
        onDownloadFailed: "Failed downloading",

    },

    //GAMES
    MemoryGame: {
        endScreenWonTitle: "You won!",
        buttonConfirmWonLabel: "Next",
        //buttonCancelWonLabel: "Exit",
        buttonCancelWonLabel: "Done",
        endScreenLoseTitle: "You lose",
        buttonConfirmLoseLabel: "Retry",
        //buttonCancelLoseLabel: "Exit",
        buttonCancelLoseLabel: "Back",
    },
    PuzzleGame: {
        movesCountLabel: "MOVES",

    },
    GamesLoadingScreen: {
        loadingText: 'Loading',
    },

    ParentSectionPopupScreen: {
        title: "Parents only",
        hint: "Solve the following to continue",
        buttonNextLabel: "Continue",
        instructionsText: "Don't have a passcode?",
        buttonSendEmailLabel: "Create one",
        warningInsertCode: "Please solve the following to continue",
        alertWrongPasscode: "Incorrect answer",
    },

    PlanSelection: {
        screenTitle: "Select your plan",

        monthly: "Monthly",
        annualy: "Annualy",
        watchAtSameTimeOneDevice: "Screen you can watch on at the same time",
        watchAtSameTime: "Screens you can watch on at the same time",
        exclusive: "Exclusive content",
        watchDevices: "Watch on mobile and tablet",
        cancel: "Cancel anytime",
        buttonLabel: "Subscribe",
        noPlanSelected: "Please select a plan first",
    },

    SubscribeNowPopup: {
        title: "Unlock to access",
        hint: "Unlock and enjoy songs, stories and more!",
        buttonLabel: "Subscribe now",
        Upgrade: 'Upgrade your plan to access',
        UpgradeBTN: 'Purchase subscription plans'
    },
    CurrentSubscriptionScreen: {
        title: "Current subscription",
        monthly: "Monthly",
        annualy: "Annualy",
        upgradeSubscriptionButtonLabel: "Change subscription",
        cancelSubscriptionButtonLabel: "Cancel subscription",
        renewDate: "Renew date: "
    },

    ForceLogoutScreen: {
        title: "Logout from one of the devices below to continue",
        buttonLabel: "Logout",
        loggedOutInfo: "This device logged out by ",
        buttonLoginLabel: "Login"
    },
    InternetConnectionError: {
        message: "Please connect to the internet",
        buttonLabelRetry: "Retry",
        buttonLabelGoToDownloads: "Go to downloads"
    },
    footer: {
        rights: 'All rights reserved',
        royakids: 'Roya Media Group',
        about: 'About Us',
        privacy: 'Privacy Policy',
        conditions: 'Terms and conditions',
        contact: 'Contact Us',
        contacttxt: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        abouttxt: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',


    },
    checkout: {
        title: 'Checkout',
        planName: 'Plan Name',
        planType: 'Plan Type',
        totalBilled: 'Total Billed',
        method: 'Payment Method::',
        sucss: 'Your payment was successful!',
        error: 'Payment has not been completed!',


        continue: 'continue',
        email: 'Email',
        name: 'Full name',
        country: 'Country',
        city: 'City',
        address: 'Address ',
        titletop: 'You`re almost there! We would like you to fill in more details to continue your safe payment process.',
    },

    countactus: {
        name: 'Your Name',
        email: 'Your Email',
        msg: 'Your Message',
        btn: 'Send',
        ErrorMsg: 'Please enter all fields!',
        SucMsg: 'Your message has been sent successfully!'
    }
};