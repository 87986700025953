import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Homepage from './Component/Homepage';
import Login from './Component/Login';
import CreateAccount from './Component/CreateAccount';
import Forgetpassword from './Component/Forgetpassword';
import HomeScreen from './Component/HomeScreen';
import ContentView from './Component/ContentView';
import Season from './Component/Season';
import Episode from './Component/Episode';
import Search from './Component/Search';
import Favorites from './Component/Favorites';
import Parents from './Component/Parents';
import Settings from './Component/Settings';
import EditProfile from './Component/settingscomponent/EditProfile';
import History from './Component/History';
import Createprofile from './Component/CreateAccountStep2';
import Playlist from './Component/playlist';
import Interests from './Component/Interests';
import CreateProfile2 from './Component/CreateProfile';
import PlaylistItem from './Component/playlistItem';
import playlistContent from './Component/playlist_content';
import Subscription from './Component/Subscription';
import CheckOut from './Component/CheckOut';
import CreateAccount223 from './Component/CreateAccount223';

import Hyperpay from './Component/Hyperpay';
import Hyperpay2 from './Component/Hyperpay2';
import Mondia from './Component/mondia';
import Palestine from './Component/Palestine';
import Mondia2 from './Component/mondia2';

import { TransitionGroup, CSSTransition } from "react-transition-group";



const RouterPublic = () => (
  <Router>
    <Route
      render={({ location }) => {
        return (
          <TransitionGroup className="RTG">
            <CSSTransition key={location.key} timeout={500} classNames="fade">
              <div>
                <Switch location={location}>
                  <Route exact path={process.env.REACT_APP_FRONT_URL + ""} component={Homepage} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "login"} component={Login} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "createAccount"} component={CreateAccount} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "CreateAccount223"} component={CreateAccount223} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "forgetpassword"} component={Forgetpassword} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "home"} component={HomeScreen} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "content/:content/:id"} component={ContentView} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "season/:id/:title"} component={Season} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "episode/:id/:title/:title2"} component={Episode} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "search"} component={Search} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "favorites"} component={Favorites} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "parents"} component={Parents} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "settings"} component={Settings} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "settings/:id/edit"} component={EditProfile} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "settings/history"} component={History} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "createprofile"} component={Createprofile} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "playlist"} component={Playlist} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "playlist/:id/edit"} component={PlaylistItem} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "playlist/:id"} component={playlistContent} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "interests"} component={Interests} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "settings/CreateProfile"} component={CreateProfile2} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "subscription"} component={Subscription} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "checkout"} component={CheckOut} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "hyperpay/:id/checkout"} component={Hyperpay} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "hyperpay/status"} component={Hyperpay2} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "mondia/:id/subscribe"} component={Mondia} />
                  <Route exact path={process.env.REACT_APP_FRONT_URL + "mondia/subscribe/result/:id"} component={Mondia2} />


                  <Route exact path={process.env.REACT_APP_FRONT_URL + "palestine/:id/subscribe"} component={Palestine} />


                  <Route render={() => <div>Not Found</div>} />
                </Switch>
              </div>
            </CSSTransition>
          </TransitionGroup>
        );
      }}
    />
  </Router>
);

export default RouterPublic;
