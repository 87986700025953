import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Axios from 'axios';
import Carousel from 'react-elastic-carousel';

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Base64 } from 'js-base64';

import InfoConent from './popup/infoConent';
import ActionContent from './popup/actionContent';
import { Tooltip, Zoom } from '@material-ui/core';


let lang = require('../lang/en').default;


if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
    localStorage.setItem('lang', 'En');
    lang = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang = require('../lang/en').default;
    }
    else {
        lang = require('../lang/ar').default;
    }
}



class Search extends Component {
    state = {
        items: [],
        type: 'all',
        popupshow: [],
        tags: [],
        showPopup: false,
        showPopup2: false,
        favSt: '',
        playlistName: '',
        selectType: 'songs',
        songs: [],
        shows: [],
        stories: [],
        songsSelected: [],
        showsSelected: [],
        storiesSelected: [],
        lang: lang,
        popupSub: false
    }

    changeType = (type) => {
        var arr = JSON.parse(localStorage.getItem('token'));
        this.setState({
            type: type,
            showPopup: false,
            showPopup2: false,
            items: []
        })





        let formData2 = new FormData();
        formData2.append('access_token', arr.access_token);


        formData2.append('playListId', this.props.match.params.id);

        formData2.append('lang', localStorage.getItem('lang'));

        Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/getContent",
            formData2,
        ).then(response2 => {
            this.setState({
                playlistName: response2.data.playListName,
                songsSelected: response2.data.allarray[0].songslist,
                storiesSelected: response2.data.allarray[2].storieslist,
                showsSelected: response2.data.allarray[1].showslist
            })


            let allarray = [];
            if (this.state.type == 'all') {
                this.state.songsSelected.map((item) => allarray.push(item));
                this.state.storiesSelected.map((item) => allarray.push(item));
                this.state.showsSelected.map((item) => allarray.push(item));
            }
            else if (this.state.type == 'songs') {
                this.state.songsSelected.map((item) => allarray.push(item));
            }
            else if (this.state.type == 'stories') {
                this.state.storiesSelected.map((item) => allarray.push(item));
            }
            else if (this.state.type == 'shows') {
                this.state.showsSelected.map((item) => allarray.push(item));
            }
            this.setState({
                items: allarray
            })

        })


    }
    componentDidMount() {
        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/accesscode";
            let formData = new FormData();
            formData.append("access_token", arr.access_token);
            Axios.post(url,
                formData,
            ).then(response => {
                if (response.data.data == undefined) {
                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                }
                else {

                    let formData2 = new FormData();
                    formData2.append('access_token', arr.access_token);


                    formData2.append('playListId', this.props.match.params.id);

                    formData2.append('lang', localStorage.getItem('lang'));

                    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/getContent",
                        formData2,
                    ).then(response2 => {
                        console.log(response2.data);
                        this.setState({
                            playlistName: response2.data.playListName,
                            songsSelected: response2.data.allarray[0].songslist,
                            storiesSelected: response2.data.allarray[2].storieslist,
                            showsSelected: response2.data.allarray[1].showslist
                        })


                        let allarray = [];
                        if (this.state.type == 'all') {
                            this.state.songsSelected.map((item) => allarray.push(item));
                            this.state.storiesSelected.map((item) => allarray.push(item));
                            this.state.showsSelected.map((item) => allarray.push(item));
                        }
                        else if (this.state.type == 'songs') {
                            this.state.songsSelected.map((item) => allarray.push(item));
                        }
                        else if (this.state.type == 'stories') {
                            this.state.storiesSelected.map((item) => allarray.push(item));
                        }
                        else if (this.state.type == 'shows') {
                            this.state.showsSelected.map((item) => allarray.push(item));
                        }
                        this.setState({
                            items: allarray
                        })

                    })




                }
            })

        }
    }

    showPopupAction(video) {
        var arr = JSON.parse(localStorage.getItem('token'));

        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/getFavoriteStatus";
        let formData = new FormData();
        formData.append('access_token', arr.access_token);
        formData.append('profile_id', arr.UserData._id);
        formData.append('contentID', video._id);
        formData.append('lang', localStorage.getItem('lang'));


        Axios.post(url,
            formData,
        ).then(response2 => {
            console.log(response2.data)
            if (response2.data.status == true) {
                this.setState({
                    popupshow: video,
                    showPopup: false,
                    showPopup2: true,
                    favSt: true
                })
            }
            else {
                this.setState({
                    popupshow: video,
                    showPopup: false,
                    showPopup2: true,
                    favSt: false
                })
            }
        })


    }

    render() {

        return (
            <div>
                <section id="Search" >
                    <div className="popupSub" style={{ opacity: this.state.popupSub ? 1 : 0, visibility: this.state.popupSub ? 'visible' : 'hidden', transition: 'all 0.7s' }}>
                        <div className="colsebtn" onClick={() => this.setState({ popupSub: false })}>X</div>
                        <div className="boxPopup">

                            <img src={require("../assets/img/Lock icon.png")} />
                            <div className="textPopup">{this.state.lang['SubscribeNowPopup'].Upgrade}</div>
                            <div className="btns">
                                <a className="btn1"
                                    onClick={() => {
                                        this.props.history.push(process.env.REACT_APP_FRONT_URL + 'subscription');
                                    }}
                                >{this.state.lang['SubscribeNowPopup'].UpgradeBTN}</a>
                            </div>
                        </div>

                    </div>
                    <div className="header">
                        <div className="leftSec">
                            <img className="img pointer" src="https://royakids.tv/websitedemo/logo.png" onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} />

                            <img id="backbtn" onClick={() => {
                                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'playlist');
                            }} src={require("../assets/img/Group 4877.png")} />
                            <div className="SearchTitle">{this.state.playlistName}</div>
                        </div>
                        <div className="centerSec" style={{ marginLeft: localStorage.getItem('lang') != 'Ar' ? '-10vw' : '5vw' }}>
                            <div className={this.state.type == "all" ? "all activeAll" : "all"}
                                onClick={() => {

                                    this.changeType('all')

                                }}
                            >
                                <div className="typetext">{this.state.lang['PlaylistScreen'].all}</div>

                            </div>
                            <div className={this.state.type == "songs" ? "Songs activeSongs" : "Songs"}
                                onClick={() => {
                                    this.changeType('songs')
                                }}
                            >
                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelSongsCount}</div>
                            </div>
                            <div className={this.state.type == "stories" ? "Stories activeStories" : "Stories"}
                                onClick={() => {
                                    this.changeType('stories')

                                }}
                            >
                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelStoriesCount}</div>

                            </div>
                            <div className={this.state.type == "shows" ? "Shows activeShows" : "Shows"}
                                onClick={() => {

                                    this.changeType('shows')

                                }}
                            >

                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelShowsCount}</div>
                            </div>
                        </div>
                        <div className="rightSec">
                            <div>
                                <a className="btn1" onClick={() => {
                                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'playlist/' + this.props.match.params.id + '/edit');
                                }}>
                                    {this.state.lang['PlaylistScreen'].editButtonLabel}
                                </a>
                                <Tooltip TransitionComponent={Zoom} title={localStorage.getItem('lang') === 'Ar' ? "حسابي" : "My account"} aria-label={localStorage.getItem('lang') == 'Ar' ? "حسابي" : "My account"} arrow>
                                    <div>
                                        <img src={localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' ? localStorage.getItem('minImg') ? Base64.decode(localStorage.getItem('minImg')) : '' : require("../assets/img/Group 4967.png") : ''} onClick={() => {
                                            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'parents');
                                        }} />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                    </div>
                    <ActionContent show2={{ showPopup2: this.state.showPopup2, data2: this.state.popupshow, favSt: this.state.favSt, type: this.state.type }} />
                    <InfoConent show={{ showPopup: this.state.showPopup, data: this.state.popupshow, tags: this.state.tags, type: this.state.type }} />
                    <div className="bodyHome">
                        <div className="Result">
                            {

                                this.state.items.length != 0 ?

                                    this.state.items.map((video, index) =>
                                        video != null ?
                                            <div key={index}>
                                                <div key={video._id} className="item">

                                                    <img id="more" src={require("../assets/img/Group 855.png")}
                                                        onClick={(() => {
                                                            this.showPopupAction(video)

                                                        })}
                                                    />
                                                    <img id="info" src={require("../assets/img/Group 4983.png")}
                                                        onClick={(() => {
                                                            this.setState({
                                                                popupshow: video,
                                                                tags: [],
                                                                showPopup2: false,
                                                                showPopup: true
                                                            })
                                                        })}
                                                    />
                                                    <div className="lockIcon" style={{ display: !video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3') ? 'none' : '' }}></div>

                                                    <div className="gotocontent"
                                                        onClick={() => {
                                                            if (!video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3'))
                                                                if (video.typeOFContent != 'shows')
                                                                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'content/' + video.typeOFContent + '/' + video._id);
                                                                else
                                                                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'season/' + video._id + '/' + video.title);
                                                            else
                                                                this.setState({
                                                                    popupSub: true,
                                                                    showPopup2: false,
                                                                    showPopup: false
                                                                })


                                                        }}
                                                    ></div>
                                                    <img className="mainImg" src={process.env.REACT_APP_BACKEND_URL + "/public/" + video.coverImg}></img>
                                                    {/* <div className="titleitem">{video.title}</div> */}
                                                </div>

                                            </div>

                                            : ''



                                    )
                                    :

                                    <div className="nocontent">{this.state.lang['Shared'].noContentsAvailable2}</div>

                            }
                        </div>
                    </div>
                </section>


            </div >
        );
    }
}

export default Search;