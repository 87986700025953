import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAlert } from "react-alert";

import Footer from '../Component/pageSec/footer';


let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}



export default function Forgetpassword() {
    const [email, setEmail] = React.useState(null);
    const [msgError, setMsgError] = React.useState(null);
    const [msgsuc, setmsgSuc] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const alert = useAlert();

    let history = useHistory();

    const handleClick = (event) => {
        // setAnchorEl(event.currentTarget);
        if (email != null) {
            setLoading(true)
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/resetpassworduser";
            let formData = new FormData();
            formData.append('email', email);

            formData.append('lang', localStorage.getItem('lang'));

            Axios.post(url,
                formData,
            ).then(response => {
                setEmail('')
                setLoading(false)
                if (response.data.booleanValue) {

                    setmsgSuc(response.data.msg)
                    setMsgError(null)
                }
                else {

                    setMsgError(response.data.msg)
                    setmsgSuc(null)
                }


            })
        }
        else {
            setMsgError(lang1['AlertMessages'].emptyEmail)
            setmsgSuc(null)
        }

    };

    const handleClose = () => {
        // setAnchorEl(null);
    };
    useEffect(() => {
    }, []);



    return (
        <div>
            <section id="footer" >
                <div className="stage">

                    <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                    <img id="cloud2" src={require("../assets/img/Path 2337.png")} />
                    <img id="backbtnImg" className="img pointer" src="https://royakids.tv/websitedemo/logo.png" onClick={() => { history.push(process.env.REACT_APP_FRONT_URL) }} />

                    {/* <img id="backbtn" onClick={() => {
                        history.goBack()
                    }} src={require("../assets/img/Group 4877.png")} /> */}

                </div>

                <div className="title">
                    <div className="img">
                        <img src={require("../assets/img/Group 1380.png")} />
                    </div>
                    <div className="textTitle">
                        {lang1['ResetPasswordScreen'].title}
                    </div>
                    <div className="errorMsg" style={{ opacity: msgError != null ? '1' : '0', visibility: msgError != null ? 'visible' : 'hidden', transition: 'all 1s', height: msgError != null ? 'auto' : '0' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="33" viewBox="0 0 29 33">
                            <g id="Group_4992" data-name="Group 4992" transform="translate(-13 -9.802)">
                                <g id="Group_1332" data-name="Group 1332" transform="translate(13.213 12.27)">
                                    <circle id="Ellipse_386" data-name="Ellipse 386" cx="14.5" cy="14.5" r="14.5" transform="translate(-0.213 -0.468)" fill="#fff" />
                                </g>
                                <text id="_" data-name="!" transform="translate(25 36.802)" fill="#fd7777" font-size="25" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">!</tspan></text>
                            </g>
                        </svg>

                        {msgError}</div>

                    <div className="errorMsg" style={{ opacity: msgsuc != null ? '1' : '0', visibility: msgsuc != null ? 'visible' : 'hidden', transition: 'all 1s', backgroundColor: '#65daa3', height: msgsuc != null ? 'auto' : '0' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="33" viewBox="0 0 29 33">
                            <g id="Group_4992" data-name="Group 4992" transform="translate(-13 -9.802)">
                                <g id="Group_1332" data-name="Group 1332" transform="translate(13.213 12.27)">
                                    <circle id="Ellipse_386" data-name="Ellipse 386" cx="14.5" cy="14.5" r="14.5" transform="translate(-0.213 -0.468)" fill="#fff" />
                                </g>
                                <text id="_" data-name="!" transform="translate(25 36.802)" fill="#65daa3" font-size="25" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">!</tspan></text>
                            </g>
                        </svg>

                        {msgsuc}</div>
                    <div className="input">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.312" height="15.755" viewBox="0 0 19.312 15.755">
                            <g id="Group_1159" data-name="Group 1159" transform="translate(1 1)">
                                <path id="Path_62" data-name="Path 62" d="M1138.33,560.429v8.736a2.51,2.51,0,0,0,2.509,2.509h12.294a2.51,2.51,0,0,0,2.509-2.509v-8.736a2.46,2.46,0,0,0-.522-1.526,2.486,2.486,0,0,0-1.987-.984h-12.294a2.509,2.509,0,0,0-2.509,2.509Z" transform="translate(-1138.33 -557.92)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                <path id="Path_63" data-name="Path 63" d="M1139.37,558.9l5.635,5.635a3.541,3.541,0,0,0,5,0l5.635-5.635a2.486,2.486,0,0,0-1.987-.984h-12.294A2.5,2.5,0,0,0,1139.37,558.9Z" transform="translate(-1138.853 -557.92)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                <path id="Path_64" data-name="Path 64" d="M1145.3,571.63a2.741,2.741,0,0,0-.291.256l-5.635,5.635a2.5,2.5,0,0,0,1.992.984h12.294a2.486,2.486,0,0,0,1.987-.984l-5.635-5.635a2.364,2.364,0,0,0-.3-.256" transform="translate(-1138.853 -564.75)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                            </g>
                        </svg>
                        <input type="text" placeholder={lang1['ResetPasswordScreen'].inputEmail}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />

                    </div>



                    <div className="btns">
                        <a className="btn1"
                            onClick={() => {
                                return !loading ? handleClick() : ''
                            }}
                        >
                            <img style={{ display: loading ? 'block' : 'none', textAlign: 'center', margin: 'auto' }} id="cloud1" src={require("../assets/img/loading.gif")} />

                            <span style={{ display: !loading ? 'block' : 'none' }}>{lang1['ResetPasswordScreen'].buttonNext}</span>

                        </a>
                    </div>
                    <div className="textunderphone">
                        {lang1['ResetPasswordScreen'].instruction}
                    </div>
                </div>
                <Footer />
            </section>


        </div>
    );
}