import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory, withRouter } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useAlert } from "react-alert";

import MaleImg from '../../assets/img/male.png';
import FemaleImg from '../../assets/img/female.png';
import Fade from '@material-ui/core/Fade';

import Resizer from 'react-image-file-resizer';



let lang1 = require('../../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
  localStorage.setItem('lang', 'En');
  lang1 = require('../../lang/en').default;
}
else {
  if (localStorage.getItem('lang') === 'En') {
    lang1 = require('../../lang/en').default;
  }
  else {
    lang1 = require('../../lang/ar').default;
  }
}


export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [plan, setPlan] = React.useState([]);
  const [load, setLoad] = React.useState(true);
  const [profileInfo, setProfileInfo] = React.useState([]);

  const alert = useAlert();
  const [name, setName] = React.useState('');
  const [avatars, setAvatars] = React.useState([]);

  const [mm, setMM] = React.useState('');
  const [dd, setDD] = React.useState('');
  const [yy, setYY] = React.useState('');
  const [img, setImg] = React.useState('');
  const [imgSend, setImgSend] = React.useState('');
  const [counterScreen, setCounterScreen] = React.useState('');

  const [gender, setGender] = React.useState('');


  const [popupShow, setPopupShow] = React.useState(false);
  const [closeIcon, setCloseIcon] = React.useState(false);
  const [popupValue, setpopupValue] = React.useState(false);
  const [popupTitle, setpopupTitle] = React.useState('');
  const [popupType, setPopupType] = React.useState('');

  const monthNames = localStorage.getItem('lang') == 'En' ? ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ] : ["يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو",
    "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"];

  function getDays() {
    let aa = [];
    for (var i = 1; i <= daysInMonth(mm + 1, yy + 1); i++) {
      aa.push(i)
    }
    return aa;
  }

  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  const years = [];
  for (var i = 1990; i <= (new Date().getFullYear() - 1); i++) {
    years.push(i);
  }
  let history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setLoad(false);
    var arr = JSON.parse(localStorage.getItem('token'));
    if (arr == undefined) {
      this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
    }
    else {
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      const url = backend_url + "/app/accesscode";
      let formData = new FormData();
      formData.append("access_token", arr.access_token);
      Axios.post(url,
        formData,
      ).then(response => {
        if (response.data.data == undefined) {
          this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {

          let backend_url = process.env.REACT_APP_BACKEND_URL;
          let formData2 = new FormData();
          formData2.append('access_token', arr.access_token);

          formData2.append('lang', 'En');

          Axios.post(backend_url + "/app/getProfile",
            formData2,
          ).then(Profiles => {
            Profiles.data.newarray.map((item) => {
              if (item._id == props.match.params.id) {
                setName(item.fname)
                var parts = item.bdate.split("-");
                var d = new Date(parts[2], parts[1] - 1, parts[0]);
                setYY(d.getFullYear())
                setMM(d.getMonth())
                setDD(parts[0])

                setImg(process.env.REACT_APP_BACKEND_URL + '/public/' + item.avatarData)
                setImgSend(process.env.REACT_APP_BACKEND_URL + '/public/' + item.avatarData)

                setGender(item.gender)

                let formData = new FormData();
                formData.append('gender', item.gender);
                Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/getavatar",
                  formData,
                ).then(response => {
                  setAvatars(response.data.result);
                  console.log(response.data.result);
                })


              }
              else {
                // history.push(process.env.REACT_APP_FRONT_URL + `login`);

              }
            })



            let formData22 = new FormData();
            formData22.append('access_token', arr.access_token);

            formData22.append('profileId', props.match.params.id);
            formData22.append('lang', localStorage.getItem('lang'));

            Axios.post(backend_url + "/app/history/counter",
              formData22,
            ).then(historyCounter => {
              setCounterScreen(historyCounter.data.counter);
              setLoad(true);

              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            })

            // /app/history/counter
          })
        }
      })

    }

  }, []);

  function deleteProfile() {
    var arr = JSON.parse(localStorage.getItem('token'));
    let idMain = JSON.parse(localStorage.getItem('mainProfile'));
    let Profiles = JSON.parse(localStorage.getItem('Profiles'));
    if (props.match.params.id == idMain._id) {
      Profiles.newarray.map((item) => {
        if (item._id != props.match.params.id) {
          let formData2 = new FormData();
          formData2.append("access_token", arr.access_token);
          formData2.append('profile_id', item._id);

          formData2.append('lang', localStorage.getItem('lang'));

          //console.log("** formData = " + JSON.stringify(formData))
          Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/setMainProfile",
            formData2,
          ).then(response => {

          })
        }
      })
    }
    let formData22 = new FormData();
    formData22.append('access_token', arr.access_token);
    formData22.append('profileId', props.match.params.id);
    formData22.append('lang', localStorage.getItem('lang'));

    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/deleteProfile",
      formData22,
    ).then(deleteProfile => {
      // console.log(deleteProfile.data)
      if (deleteProfile.data.booleanValue) {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        let formData2 = new FormData();
        formData2.append('access_token', arr.access_token);

        formData2.append('lang', localStorage.getItem('lang'));

        Axios.post(backend_url + "/app/getProfile",
          formData2,
        ).then(Profiles => {

          Profiles.data.newarray.map((pr) => {
            if (pr.mainProfile) {
              localStorage.setItem('mainProfile', JSON.stringify(pr));
            }
          })
          localStorage.setItem('Profiles', JSON.stringify(Profiles.data));



          history.push(process.env.REACT_APP_FRONT_URL + `settings`);
        })


      }
      else {
        alert.error(deleteProfile.data.msg);

      }
    })

  }

  function clearHistory() {
    setLoad(false);
    var arr = JSON.parse(localStorage.getItem('token'));
    let formData22 = new FormData();
    formData22.append('access_token', arr.access_token);
    formData22.append('profileId', props.match.params.id);

    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/history/clear",
      formData22,
    ).then(deleteProfile => {
      let formData22 = new FormData();
      formData22.append('access_token', arr.access_token);

      formData22.append('profileId', props.match.params.id);
      formData22.append('lang', localStorage.getItem('lang'));

      Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/history/counter",
        formData22,
      ).then(historyCounter => {
        setCounterScreen(historyCounter.data.counter);
        setLoad(true);
      })
    })

  }
  function saveUpdate() {
    var arr = JSON.parse(localStorage.getItem('token'));


    let formData = new FormData();
    formData.append('access_token', arr.access_token);
    formData.append('profile_id', props.match.params.id);
    formData.append('fname', name);
    formData.append('bdate', (dd + '-' + (mm + 1) + '-' + yy));
    formData.append('gender', gender);

    formData.append('lang', localStorage.getItem('lang'));


    if (imgSend != img)
      formData.append('pimage', img.replace(process.env.REACT_APP_BACKEND_URL + '/public/', ""));
    else {
      formData.append('pimage', '');
    }

    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/updateProfile",
      formData,
    ).then(save => {
      console.log(save)
      history.push(process.env.REACT_APP_FRONT_URL + `home`);

    })

  }

  function fileUpload(e, id) {
    // let image = document.getElementById(id);
    // image.src = URL.createObjectURL(e.target.files[0]);
    // setImgSend(e.target.files[0]);
    // // console.log(e.target.files[0])

    Resizer.imageFileResizer(
      e.target.files[0],
      300,
      300,
      'JPEG',
      100,
      0,
      uri => {
        setImg(uri)
        // this.setState({ newImage: uri })
      },
      'base64',
      200,
      200,
    );


    // let reader = new FileReader();
    // reader.readAsDataURL(e.target.files[0]);
    // reader.onload = function () {
    // };
    // reader.onerror = function (error) {
    //   console.log('Error: ', error);
    // };
  };
  function editInterests() {
    localStorage.setItem('Interests', props.match.params.id);
    localStorage.setItem('InterestsEdit', '1');
    history.push(process.env.REACT_APP_FRONT_URL + 'interests');
  }

  return (
    <div>
      <Backdrop className="backdrop" open={!load}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="popup" style={{ display: popupShow ? 'block' : 'none' }}>
        <div className="popupBox">
          <div className="titleBox">{popupTitle}</div>
          <div className="btns">
            <a className="btn1" onClick={() => {
              if (popupType == 1) {
                deleteProfile();
                setPopupShow(false);
              }
              else if (popupType == 2) {
                clearHistory();
                setPopupShow(false);
              }
            }}>{lang1['SaveChangesPopup'].buttonOkLabel}</a>
            <a className="btn2" onClick={() => {
              setPopupShow(false);
            }}>{lang1['SaveChangesPopup'].buttonCancelLabel}</a>
          </div>
        </div>
      </div>
      <section id="EditProfile" style={{ display: load ? 'block' : 'none' }} >

        <div className="header">
          <div className="leftSec">
            <a className="btn2" onClick={() => {
              history.push(process.env.REACT_APP_FRONT_URL + `settings`);
            }}>{lang1['UpdateProfileScreen'].buttonCancelLabel}</a>
          </div>
          <div className="centerSec">

            <div className="settingsTitle">{lang1['HeaderTitles'].titleSettings}</div>
          </div>
          <div className="rightSec">
            <a className="btn1" onClick={() => {
              saveUpdate()
            }}>{lang1['UpdateProfileScreen'].buttonSaveLabel}</a>
          </div>
        </div>
        <div className="bodyEdit">
          <div className="titleEdit">{lang1['UpdateProfileScreen'].titleEditSettings} {name}</div>
          <div className="formProfile">
            <div className="row">
              <div className="imgProfile">
                <Button variant="contained" component="label" className="editimg" onClick={() => setCloseIcon(true)} > </Button>

                <img src={img} id="cover-image" />
                <div className="btnEditInterests" onClick={() => { editInterests() }}>{lang1['UpdateProfileScreen'].buttonEditInterestsLabel}</div>
              </div>
              <div className="formdata">
                <div className="rowinner">
                  <div className="titleRow">{lang1['UpdateProfileScreen'].childNameLabel}</div>
                  <TextField
                    value={name}
                    variant="outlined"
                    style={{ width: '18.5vw', backgroundColor: '#fff' }}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="rowinner">
                  <div className="titleRow">{lang1['UpdateProfileScreen'].dateOfBirthLabel}</div>


                  <FormControl variant="outlined">
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={dd}
                      onChange={(e) => {
                        setDD(e.target.value);
                      }}
                      className="inputDate"
                    >
                      {
                        getDays().map((item, index) =>
                          <MenuItem key={index} value={item}>{item}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>


                  <FormControl variant="outlined">
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={mm}
                      onChange={(e) => {
                        setMM(e.target.value);
                      }}
                      className="inputDate"
                    >
                      {monthNames.map((item, index) =>
                        <MenuItem key={index} value={index}>{item}</MenuItem>
                      )}

                    </Select>
                  </FormControl>
                  <FormControl variant="outlined">
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={yy}
                      onChange={(e) => {
                        setYY(e.target.value);
                      }}
                      className="inputDate"
                    >
                      {years.map((item, index) =>
                        <MenuItem key={index} value={item}>{item}</MenuItem>
                      )}
                    </Select>
                  </FormControl>

                </div>
                <div className="rowinner2">
                  <div className="titleRow">{lang1['UpdateProfileScreen'].genderLabel}</div>
                  <div className="gendertype" onClick={() => setGender('m')}>
                    <img src={'https://royakids.tv/websitedemo/male.png'} />
                    <div className="textgender" style={{ opacity: gender == 'm' ? '1' : '0.5' }}>{lang1['UpdateProfileScreen'].buttonMaleLabel} <b style={{ display: gender == 'm' ? 'block' : 'none', padding: '0 5px' }}><svg xmlns="http://www.w3.org/2000/svg" width="22.086" height="22.086" viewBox="0 0 22.086 22.086">
                      <path id="Path_2388" data-name="Path 2388" d="M6.345,0,.262.285,0,14.57" transform="translate(8.184 18.438) rotate(-135)" fill="none" stroke="#7b67db" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" />
                    </svg>
                    </b></div>
                  </div>
                  <div className="gendertype" onClick={() => setGender('f')}>
                    <img src={'https://royakids.tv/websitedemo/female.png'} />
                    <div className="textgender" style={{ opacity: gender == 'f' ? '1' : '0.5' }}>{lang1['UpdateProfileScreen'].buttonFemaleLabel} <b style={{ display: gender == 'f' ? 'block' : 'none', padding: '0 5px' }}><svg xmlns="http://www.w3.org/2000/svg" width="22.086" height="22.086" viewBox="0 0 22.086 22.086">
                      <path id="Path_2388" data-name="Path 2388" d="M6.345,0,.262.285,0,14.57" transform="translate(8.184 18.438) rotate(-135)" fill="none" stroke="#7b67db" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" />
                    </svg>
                    </b></div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="formeditImgProfile" style={{ visibility: closeIcon ? 'visible' : 'hidden', opacity: closeIcon ? '1' : '0', transition: 'all 0.65s' }}>
            <div className="closeIcon" onClick={() => {
              setCloseIcon(false);
              setImg(imgSend);
            }}>X</div>
            <div className="imgProfile">
              <Button variant="contained" component="label" className="editimg" >
                <input
                  accept="image/x-png,image/gif,image/jpeg"
                  type="file"
                  onChange={(e) => {
                    fileUpload(e, "cover-image");
                  }}
                  style={{ display: "none" }}
                />
              </Button>

              <img src={img} id="cover-image" />

            </div>
            <div className="textTitle">
              {lang1['RegistrationChildProfileImageScreen'].title}
            </div>

            <div className="avatar">
              <div className="titleAvatar">{lang1['RegistrationChildProfileImageScreen'].titleSub} </div>
              <div className="listAvatar">
                {avatars.map((item) => {
                  return <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.imgUpload} style={{ backgroundColor: item.hexcode }} onClick={
                    () => {
                      setImg(process.env.REACT_APP_BACKEND_URL + '/public/' + item.imgUpload)
                    }
                  } />
                })}

              </div>
            </div>

            <div className="btns">
              <a className={true ? "btn1" : "disbtn"}
                onClick={() => {
                  setCloseIcon(false)
                }}
              >{lang1['RegistrationChildProfileImageScreen'].buttonContinueLabel}</a>
            </div>


          </div>
        </div>
        <div className="bodyEdit2">
          <div className="History">
            <div className="row">
              <div className="leftSec">
                <svg width="51.417" height="51.417" viewBox="0 0 51.417 51.417">
                  <g id="Group_4988" data-name="Group 4988" transform="translate(-636.743 -800.49)">
                    <circle id="Ellipse_452" data-name="Ellipse 452" cx="25.709" cy="25.709" r="25.709" transform="translate(636.743 800.49)" fill="#7b67db" />
                    <g id="Group_4987" data-name="Group 4987">


                      <path id="Path_2557" data-name="Path 2557" d="M783.848,228.908a1.344,1.344,0,0,1-1.344-1.343v-7.833a1.344,1.344,0,0,1,2.688,0v7.833A1.344,1.344,0,0,1,783.848,228.908Z" transform="translate(-120.968 597.075)" fill="#fff" opacity="0.593" />
                      <path id="Path_2568" data-name="Path 2568" d="M1.344,11.527A1.344,1.344,0,0,1,0,10.184l.189-8.84a1.344,1.344,0,0,1,2.688,0l-.189,8.84A1.344,1.344,0,0,1,1.344,11.527Z" transform="matrix(0.616, -0.788, 0.788, 0.616, 661.907, 827.181)" fill="#fff" opacity="0.593" />
                      <path id="Path_2558" data-name="Path 2558" d="M781.671,267.776a2.283,2.283,0,1,1-2.283-2.283A2.283,2.283,0,0,1,781.671,267.776Z" transform="translate(-116.507 558.164)" fill="#fff" />
                      <path id="Path_2567" data-name="Path 2567" d="M614.194,112.341A18.038,18.038,0,0,0,596.223,129.1l-.208-.208a1.845,1.845,0,1,0-2.609,2.609l3.322,3.322a1.845,1.845,0,0,0,2.609,0l3.322-3.322a1.845,1.845,0,1,0-2.609-2.609l-.118.118a14.326,14.326,0,1,1,14.262,15.675,1.845,1.845,0,1,0,0,3.69,18.016,18.016,0,0,0,0-36.031Z" transform="translate(48.632 695.583)" fill="#fff" />
                    </g>
                  </g>
                </svg>
                {lang1['UpdateProfileScreen'].labelHistory}
              </div>
              <div className="rightSec">
                <div className="rowSec"
                  onClick={() => {
                    history.push(process.env.REACT_APP_FRONT_URL + `settings/history`);

                  }}
                >
                  <div className="titleSec">
                    {lang1['UpdateProfileScreen'].labelWatchHistory}
                  </div>
                  <div>
                    {
                      localStorage.getItem('lang') == 'Ar' ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="62.472" height="62.472" viewBox="0 0 62.472 62.472">
                          <g id="Group_4981" data-name="Group 4981" transform="translate(-1223.647 -755.265)">
                            <circle id="Ellipse_454" data-name="Ellipse 454" cx="24.574" cy="24.574" r="24.574" transform="translate(1286.12 801.736) rotate(161)" fill="#ffb601" />
                            <g id="Group_2196" data-name="Group 2196" transform="translate(1264.641 793.61) rotate(180)">
                              <path id="Path_323" data-name="Path 323" d="M-1203.961,378.87l7.259,7.258-7.255,7.258" transform="translate(1216.545 -378.87)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                              <path id="Path_2554" data-name="Path 2554" d="M32.568,0H12.726" transform="translate(-12.726 7.257)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4" />
                            </g>
                          </g>
                        </svg>
                        : <svg xmlns="http://www.w3.org/2000/svg" width="49.149" height="49.149" viewBox="0 0 49.149 49.149">
                          <g id="Group_4989" data-name="Group 4989" transform="translate(-1230.309 -761.927)">
                            <circle id="Ellipse_454" data-name="Ellipse 454" cx="24.574" cy="24.574" r="24.574" transform="translate(1230.309 761.927)" fill="#ffb601" />
                            <g id="Group_2196" data-name="Group 2196" transform="translate(1244.798 779.093)">
                              <path id="Path_323" data-name="Path 323" d="M-1203.961,378.87l7.259,7.258-7.255,7.258" transform="translate(1216.545 -378.87)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                              <path id="Path_2554" data-name="Path 2554" d="M32.568,0H12.726" transform="translate(-12.726 7.257)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4" />
                            </g>
                          </g>
                        </svg>
                    }


                  </div>
                </div>
                <div className="rowSec"
                  onClick={() => {
                    setpopupTitle(lang1['UpdateProfileScreen'].labelClearHistory);
                    setPopupType(2);
                    setPopupShow(true);

                  }}
                ><div className="titleSec">{lang1['UpdateProfileScreen'].labelClearHistory}</div>
                  <div>
                    {
                      localStorage.getItem('lang') == 'Ar' ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="62.472" height="62.472" viewBox="0 0 62.472 62.472">
                          <g id="Group_4981" data-name="Group 4981" transform="translate(-1223.647 -755.265)">
                            <circle id="Ellipse_454" data-name="Ellipse 454" cx="24.574" cy="24.574" r="24.574" transform="translate(1286.12 801.736) rotate(161)" fill="#ffb601" />
                            <g id="Group_2196" data-name="Group 2196" transform="translate(1264.641 793.61) rotate(180)">
                              <path id="Path_323" data-name="Path 323" d="M-1203.961,378.87l7.259,7.258-7.255,7.258" transform="translate(1216.545 -378.87)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                              <path id="Path_2554" data-name="Path 2554" d="M32.568,0H12.726" transform="translate(-12.726 7.257)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4" />
                            </g>
                          </g>
                        </svg>
                        : <svg xmlns="http://www.w3.org/2000/svg" width="49.149" height="49.149" viewBox="0 0 49.149 49.149">
                          <g id="Group_4989" data-name="Group 4989" transform="translate(-1230.309 -761.927)">
                            <circle id="Ellipse_454" data-name="Ellipse 454" cx="24.574" cy="24.574" r="24.574" transform="translate(1230.309 761.927)" fill="#ffb601" />
                            <g id="Group_2196" data-name="Group 2196" transform="translate(1244.798 779.093)">
                              <path id="Path_323" data-name="Path 323" d="M-1203.961,378.87l7.259,7.258-7.255,7.258" transform="translate(1216.545 -378.87)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                              <path id="Path_2554" data-name="Path 2554" d="M32.568,0H12.726" transform="translate(-12.726 7.257)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4" />
                            </g>
                          </g>
                        </svg>
                    }

                  </div>
                </div>
              </div>
            </div>



            <div className="row">
              <div className="ScreenTime">
                <svg xmlns="http://www.w3.org/2000/svg" width="51.417" height="51.417" viewBox="0 0 51.417 51.417">
                  <g id="Group_4990" data-name="Group 4990" transform="translate(-636.743 -948.692)">
                    <circle id="Ellipse_457" data-name="Ellipse 457" cx="25.709" cy="25.709" r="25.709" transform="translate(636.743 948.692)" fill="#65daa3" />
                    <rect id="Rectangle_1316" data-name="Rectangle 1316" width="21.143" height="33.106" rx="4" transform="translate(648.611 958.381)" fill="#fff" />
                    <rect id="Rectangle_1317" data-name="Rectangle 1317" width="15.374" height="23.717" rx="2" transform="translate(651.619 960.988)" fill="#65daa3" opacity="0.995" />
                    <path id="Path_2573" data-name="Path 2573" d="M794.888,274.385A8.892,8.892,0,1,1,786,265.493,8.891,8.891,0,0,1,794.888,274.385Z" transform="translate(-117.186 698.333)" fill="#fff" />
                    <path id="Path_2574" data-name="Path 2574" d="M-3157.331-13345.981v4.186l2.6,2.792" transform="translate(3826.196 14315.183)" fill="none" stroke="#65daa3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                  </g>
                </svg>

                {lang1['UpdateProfileScreen'].labelScreenTime}: <div className="timer"> {counterScreen} </div>
              </div>

            </div>

            <div className="row">
              <div className="ScreenTime">
                <svg xmlns="http://www.w3.org/2000/svg" width="51.417" height="51.417" viewBox="0 0 51.417 51.417">
                  <g id="Group_4991" data-name="Group 4991" transform="translate(-636.743 -1052.283)">
                    <circle id="Ellipse_458" data-name="Ellipse 458" cx="25.709" cy="25.709" r="25.709" transform="translate(636.743 1052.283)" fill="#fd7777" />
                    <g id="Group_2203" data-name="Group 2203" transform="translate(649.868 1064.691)">
                      <path id="Path_2576" data-name="Path 2576" d="M3.026,0h19.5a3.026,3.026,0,0,1,3.026,3.026v2.69H0V3.026A3.026,3.026,0,0,1,3.026,0Z" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      <path id="Path_2577" data-name="Path 2577" d="M20.583,15V29.408A3.088,3.088,0,0,1,17.5,32.5H3.088A3.088,3.088,0,0,1,0,29.408V15Z" transform="translate(2.316 -5.136)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      <g id="Group_2202" data-name="Group 2202" transform="translate(5.022 12.545)">
                        <rect id="Rectangle_1325" data-name="Rectangle 1325" width="3.025" height="12.098" rx="1.512" transform="translate(0 0)" fill="#fd7777" />
                        <rect id="Rectangle_1326" data-name="Rectangle 1326" width="3.781" height="12.098" rx="1.89" transform="translate(6.049 0)" fill="#fd7777" />
                        <rect id="Rectangle_1327" data-name="Rectangle 1327" width="3.781" height="12.098" rx="1.89" transform="translate(12.098 0)" fill="#fd7777" />
                      </g>
                    </g>
                  </g>
                </svg>


                {lang1['UpdateProfileScreen'].labelDeleteProfile}: <div className="Delete" onClick={() => {
                  setpopupTitle(lang1['ManageProfilePopup'].deleteProfle);
                  setPopupType(1);
                  setPopupShow(true);

                }}> <svg style={{ direction: 'ltr' }} id="Group_2199" data-name="Group 2199" xmlns="http://www.w3.org/2000/svg" width="120.225" height="43.856" viewBox="0 0 120.225 43.856">
                    <rect id="Rectangle_1314" data-name="Rectangle 1314" width="120.226" height="43.856" rx="12" transform="translate(0 0)" fill="#fd7777" />
                    <text id="Delete" transform="translate(18.903 9.83)" fill="#fff" fontSize="20"><tspan x="30.69" y="22">   {lang1['UpdateProfileScreen'].btnDelete}</tspan></text>
                    <g id="Group_2198" data-name="Group 2198" transform="translate(12.854 7.562)">
                      <g id="Group_1327" data-name="Group 1327" transform="translate(0 0)">
                        <ellipse id="Ellipse_388" data-name="Ellipse 388" cx="14.745" cy="14.367" rx="14.745" ry="14.367" fill="#fff" />
                        <g id="Group_1326" data-name="Group 1326" transform="translate(10.099 10.389)">
                          <path id="Path_337" data-name="Path 337" d="M0,0V12.577" transform="translate(0 8.893) rotate(-135)" fill="none" stroke="#fd7777" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                          <path id="Path_338" data-name="Path 338" d="M0,12.577V0" transform="translate(0 0) rotate(-45)" fill="none" stroke="#fd7777" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>

            </div>
          </div>

        </div>
      </section>


    </div>
  );
}