import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Axios from 'axios';
import Carousel from 'react-elastic-carousel';

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Base64 } from 'js-base64';

import InfoConent from './popup/infoConent';
import ActionContent from './popup/actionContent';
import LoginPopup from './popup/loginPopup';
import { Tooltip, Zoom } from '@material-ui/core';




let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}


class Search extends Component {
    state = {
        items: [],
        type: 'songs',
        popupshow: [],
        tags: [],
        showPopup: false,
        showPopup2: false,
        showLoginPopup: false,
        favSt: '',
        keyword: '',
        genres: [],
        GenreList: [],
        GenreListselected: [],
        lang: lang1,
        popupSub: false
    }

    changeType = (type) => {
        var arr = JSON.parse(localStorage.getItem('token'));
        this.setState({
            type: type,
            showPopup: false,
            showLoginPopup: false,
            showPopup2: false
        })
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/search/getResult";
        let formData = new FormData();
        formData.append('profile_id', 'guest');
        formData.append('typeOFContent', type);

        formData.append('lang', localStorage.getItem('lang'));


        formData.append('keyword', this.state.keyword);

        formData.append('genreID', this.state.genres);


        Axios.post(url,
            formData,
        ).then(response2 => {

            this.setState({
                items: response2.data[type]
            })
        })
    }
    componentDidMount() {
        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/accesscode";
            let formData = new FormData();
            formData.append("access_token", arr.access_token);
            Axios.post(url,
                formData,
            ).then(response => {
                if (response.data.data == undefined && JSON.parse(localStorage.getItem('token')).access_token != 'guest') {
                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                }
                else {
                    let backend_url = process.env.REACT_APP_BACKEND_URL;
                    const url = backend_url + "/app/search/getResult";
                    let formData = new FormData();
                    formData.append('profile_id', 'guest');
                    formData.append('typeOFContent', this.state.type);

                    formData.append('lang', localStorage.getItem('lang'));


                    formData.append('keyword', this.state.keyword);

                    formData.append('genreID', this.state.genres);


                    Axios.post(url,
                        formData,
                    ).then(response2 => {
                        const url2 = backend_url + "/app/search/getGenre";
                        let formData2 = new FormData();
                        formData2.append('profile_id', 'guest');
                        formData2.append('lang', localStorage.getItem('lang'));
                        Axios.post(url2,
                            formData2,
                        ).then(Genre => {
                            // console.log(response2.data.allarray[0].songs)
                            this.setState({
                                items: response2.data[this.state.type],
                                GenreList: Genre.data.result
                            })
                        })


                    })
                }
            })

        }
    }

    showPopupAction(video) {
        var arr = JSON.parse(localStorage.getItem('token'));

        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/getFavoriteStatus";
        let formData = new FormData();
        formData.append('access_token', arr.access_token);
        formData.append('profile_id', arr.UserData._id);
        formData.append('contentID', video._id);
        formData.append('lang', localStorage.getItem('lang'));


        Axios.post(url,
            formData,
        ).then(response2 => {
            if (response2.data.status == true) {
                this.setState({
                    popupshow: video,
                    showPopup: false,
                    showPopup2: true,
                    favSt: true
                })
            }
            else {
                this.setState({
                    popupshow: video,
                    showPopup: false,
                    showPopup2: true,
                    favSt: false
                })
            }
        })


    }
    handleChange = (e, name) => {
        this.setState({
            [name]: e.target.value,

            showPopup: false,
            showLoginPopup: false,
            showPopup2: false
        });

        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/search/getResult";
        let formData = new FormData();
        formData.append('profile_id', 'guest');
        formData.append('typeOFContent', this.state.type);

        formData.append('lang', localStorage.getItem('lang'));


        formData.append('keyword', e.target.value);

        formData.append('genreID', this.state.genres);


        Axios.post(url,
            formData,
        ).then(response2 => {
            // console.log(this.state.type)

            this.setState({
                items: response2.data[this.state.type]
            })
        })
    };
    selectGere(val) {


        let sta = this.state;
        ///delete form old
        this.setState({
            'GenreList': sta['GenreList'].filter(item => item._id != val._id)
        })
        ///add form old
        this.setState({
            'GenreListselected': sta['GenreListselected'].concat([val]),
            'genres': sta['genres'].concat([val._id])
        })

        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/search/getResult";
        let formData = new FormData();
        formData.append('profile_id', 'guest');
        formData.append('typeOFContent', this.state.type);

        formData.append('lang', localStorage.getItem('lang'));


        formData.append('keyword', this.state.keyword);
        formData.append('genreID', this.state.genres);

        Axios.post(url,
            formData,
        ).then(response2 => {
            this.setState({
                items: response2.data[this.state.type]
            })
        })
    }
    // componentDidUpdate()
    // {
    //  this.componentDidMount()
    // }
    selectGere2(val) {
        let sta = this.state;
        ///delete form old
        this.setState({
            'GenreListselected': sta['GenreListselected'].filter(item => item._id != val._id),
            'genres': sta['genres'].filter(item => item != val._id)
        })
        ///add form old
        this.setState({
            'GenreList': sta['GenreList'].concat([val])
        })

        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/search/getResult";
        let formData = new FormData();
        formData.append('profile_id', 'guest');
        formData.append('typeOFContent', this.state.type);
        formData.append('lang', localStorage.getItem('lang'));
        formData.append('keyword', this.state.keyword);
        formData.append('genreID', this.state.genres);


        Axios.post(url,
            formData,
        ).then(response2 => {
            this.setState({
                items: response2.data[this.state.type]
            })
        })
    }
    render() {

        return (
            <div>
                <section id="Search" >
                    <div className="popupSub" style={{ opacity: this.state.popupSub ? 1 : 0, visibility: this.state.popupSub ? 'visible' : 'hidden', transition: 'all 0.7s' }}>
                        <div className="colsebtn" onClick={() => this.setState({ popupSub: false })}>X</div>
                        <div className="boxPopup">

                            <img src={require("../assets/img/Lock icon.png")} />
                            <div className="textPopup">{this.state.lang['SubscribeNowPopup'].Upgrade}</div>
                            <div className="btns">
                                <a className="btn1"
                                    onClick={() => {
                                        this.props.history.push(process.env.REACT_APP_FRONT_URL + 'subscription');
                                    }}
                                >{this.state.lang['SubscribeNowPopup'].UpgradeBTN}</a>
                            </div>
                        </div>

                    </div>
                    <div className="header">
                        <div className="leftSec">
                            <img className="img pointer" src={require("../assets/img/logo.png")} onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} />
                            <img id="backbtn" onClick={() => {
                                this.props.history.goBack()
                            }} src={require("../assets/img/Group 4877.png")} />
                            <div className="SearchTitle"> </div>
                        </div>
                        <div className="centerSec">
                            {/* <div className={this.state.type == "all" ? "all activeAll" : "all"}
                                onClick={() => {

                                    this.changeType('all')

                                }}
                            >
                                All
                            </div> */}
                            <div className={this.state.type == "songs" ? "Songs activeSongs" : "Songs"}
                                onClick={() => {
                                    this.changeType('songs')
                                }}
                            >
                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelSongsCount}</div>
                            </div>
                            <div className={this.state.type == "stories" ? "Stories activeStories" : "Stories"}
                                onClick={() => {
                                    this.changeType('stories')

                                }}
                            >
                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelStoriesCount}</div>

                            </div>
                            <div className={this.state.type == "shows" ? "Shows activeShows" : "Shows"}
                                onClick={() => {

                                    this.changeType('shows')

                                }}
                            >

                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelShowsCount}</div>
                            </div>
                        </div>
                        <div className="rightSec">
                            <div>
                                {/* <img src={require("../assets/img/logo.png")} /> */}
                                <Tooltip TransitionComponent={Zoom} title={localStorage.getItem('lang') === 'Ar' ? "حسابي" : "My account"} aria-label={localStorage.getItem('lang') == 'Ar' ? "حسابي" : "My account"} arrow>
                                    <div>
                                        <img src={localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' ? localStorage.getItem('minImg') ? Base64.decode(localStorage.getItem('minImg')) : '' : require("../assets/img/Group 4967.png") : ''} onClick={() => {
                                            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'parents');
                                        }} />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                    </div>
                    {JSON.parse(localStorage.getItem('token')).access_token != 'guest' && (
                        <ActionContent show2={{ showPopup2: this.state.showPopup2, data2: this.state.popupshow, favSt: this.state.favSt, type: this.state.type }} showPlaylist={this.state.showPlaylist} />
                    )}
                    {JSON.parse(localStorage.getItem('token')).access_token == 'guest' && (
                        <LoginPopup showLoginPopup={this.state.showLoginPopup} />
                    )}
                    <InfoConent show={{ showPopup: this.state.showPopup, data: this.state.popupshow, tags: this.state.tags, type: this.state.type }} />
                    <div className="bodyHome">

                        <div className="searchInput">


                            <input type="text" id="input-search" className="input-text input-text--search" placeholder={this.state.lang['SearchScreen'].textInputSearchLabel}
                                value={this.state.keyword}
                                onChange={(e) => {
                                    this.handleChange(e, "keyword");
                                }}
                            />


                            {/* <input type="text" className="sesrch" /> */}
                            <div className="genreitems">
                                {this.state.GenreListselected.map(itemGenre =>
                                    <div className="itemActive" onClick={() => this.selectGere2(itemGenre)}>{itemGenre.title}</div>
                                )}
                                {this.state.GenreList.map((itemGenre, index) =>
                                    <div className="item" key={index} onClick={() => this.selectGere(itemGenre)}>{itemGenre.title}</div>

                                )}

                            </div>
                        </div>
                        <div className="Result">
                            {
                                this.state.items.length != 0 ?
                                    this.state.items
                                        .slice(0, 40)
                                        .map((video, index) =>
                                            <div key={index}>
                                                <div key={video._id} className="item">

                                                    <img id="more" src={require("../assets/img/Group 855.png")}
                                                        onClick={(() => {
                                                            if (JSON.parse(localStorage.getItem('token')).access_token != 'guest')
                                                                this.showPopupAction(video)
                                                            else
                                                                this.setState({
                                                                    showLoginPopup: true,
                                                                    showPopup2: false,
                                                                    showPopup: false
                                                                })

                                                        })}
                                                    />
                                                    <img id="info" src={require("../assets/img/Group 4983.png")}
                                                        onClick={(() => {
                                                            this.setState({
                                                                popupshow: video,
                                                                tags: video.tags,
                                                                showPopup2: false,
                                                                showLoginPopup: false,
                                                                showPopup: true
                                                            })
                                                        })}
                                                    />
                                                    <div className="lockIcon" style={{ display: !video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3') ? 'none' : '' }}></div>

                                                    <div className="gotocontent"
                                                        onClick={() => {

                                                            if (!video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3'))

                                                                if (this.state.type != 'shows')
                                                                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'content/' + this.state.type + '/' + video._id);
                                                                else
                                                                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'season/' + video._id + '/' + video.title);
                                                            else
                                                                this.setState({
                                                                    popupSub: true,
                                                                    showPopup2: false,
                                                                    showPopup: false
                                                                })




                                                        }}
                                                    ></div>
                                                    <img className="mainImg" src={process.env.REACT_APP_BACKEND_URL + "/public/" + video.coverImg}></img>
                                                    {/* <div className="titleitem">{video.title}</div> */}
                                                </div>

                                            </div>





                                        )
                                    :
                                    this.state.lang['Shared'].noContentsAvailable2
                            }
                        </div>
                    </div>
                </section>


            </div >
        );
    }
}

export default Search;