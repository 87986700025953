// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/Somatic-Rounded.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/ArbFONTS-DroidKufi-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/ArbFONTS-DroidKufi-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'Somatic';\n  src: local(\"Somatic\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\"); }\n\n@font-face {\n  font-family: 'ArbFONTS-DroidKufi';\n  src: local(\"ArbFONTS-DroidKufi\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\"); }\n\n@font-face {\n  font-family: 'ArbFONTS-DroidKufi-Bold';\n  src: local(\"ArbFONTS-DroidKufi-Bold\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\"); }\n\nbody {\n  font-family: 'Somatic','ArbFONTS-DroidKufi'; }\n", ""]);
// Exports
module.exports = exports;
