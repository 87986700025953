import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Axios from 'axios';
import Carousel from 'react-elastic-carousel';

import { TransitionGroup, CSSTransition } from "react-transition-group";

import InfoConent from './popup/infoConent';
import ActionContent from './popup/actionContent';
import { Tooltip, Zoom } from '@material-ui/core';
import { Base64 } from 'js-base64';


let lang = require('../lang/en').default;


if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
    localStorage.setItem('lang', 'En');
    lang = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang = require('../lang/en').default;
    }
    else {
        lang = require('../lang/ar').default;
    }
}


class Search extends Component {
    state = {
        items: [],
        type: 'all',
        popupshow: [],
        tags: [],
        showPopup: false,
        showPopup2: false,
        favSt: '',
        keyword: '',
        genres: [],
        GenreList: [],
        GenreListselected: [],
        namePlayListNew: '',
        createPlaylist: false,
        lang: lang
    }

    changeType = (type) => {
        var arr = JSON.parse(localStorage.getItem('token'));
        this.setState({
            type: type,
            showPopup: false,
            showPopup2: false,
            items: []
        })




        let formData2 = new FormData();
        formData2.append('access_token', arr.access_token);

        let arrayProfile = JSON.parse(localStorage.getItem('Profiles'));
        let profileID = '';
        for (var z = 0; z < arrayProfile.newarray.length; z++) {
            if (arrayProfile.newarray[z].mainProfile) {
                profileID = arrayProfile.newarray[z]._id;
            }
        }

        formData2.append('profileId', profileID);

        formData2.append('lang', localStorage.getItem('lang'));

        Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/getListPlayList",
            formData2,
        ).then(response2 => {
            let allarray = [];
            if (type == 'all') {
                response2.data.items.map((item) => {
                    allarray.push(item)
                });
            }
            else if (type == 'songs') {
                response2.data.items.map((item) => {
                    if (item.songsImg.length > 0)
                        allarray.push(item)
                });
            }
            else if (type == 'stories') {
                response2.data.items.map((item) => {
                    if (item.storiesImg.length > 0) {
                        allarray.push(item)
                    }

                });
            }
            else if (type == 'shows') {
                response2.data.items.map((item) => {
                    if (item.showsImg.length > 0)
                        allarray.push(item)
                });
            }


            this.setState({
                items: allarray
            })



        })


    }
    componentDidMount() {
        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/accesscode";
            let formData = new FormData();
            formData.append("access_token", arr.access_token);
            Axios.post(url,
                formData,
            ).then(response => {
                if (response.data.data == undefined) {
                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                }
                else {

                    let formData2 = new FormData();
                    formData2.append('access_token', arr.access_token);

                    let arrayProfile = JSON.parse(localStorage.getItem('Profiles'));
                    let profileID = '';
                    for (var z = 0; z < arrayProfile.newarray.length; z++) {
                        if (arrayProfile.newarray[z].mainProfile) {
                            profileID = arrayProfile.newarray[z]._id;
                        }
                    }

                    formData2.append('profileId', profileID);

                    formData2.append('lang', localStorage.getItem('lang'));

                    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/getListPlayList",
                        formData2,
                    ).then(response2 => {
                        let allarray = [];
                        if (this.state.type == 'all') {
                            response2.data.items.map((item) => {
                                allarray.push(item)
                            });
                        }
                        else if (this.state.type == 'songs') {
                            response2.data.items.map((item) => {
                                if (item.songsImg.length > 0)
                                    allarray.push(item)
                            });
                        }
                        else if (this.state.type == 'stories') {
                            response2.data.items.map((item) => {
                                if (item.storiesImg.length > 0)
                                    allarray.push(item)
                            });
                        }
                        else if (this.state.type == 'shows') {
                            response2.data.items.map((item) => {
                                if (item.showsImg.length > 0)
                                    allarray.push(item)
                            });
                        }

                        this.setState({
                            items: allarray
                        })

                    })




                }
            })

        }
    }

    showPopupAction(video) {
        var arr = JSON.parse(localStorage.getItem('token'));

        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/getFavoriteStatus";
        let formData = new FormData();
        formData.append('access_token', arr.access_token);
        formData.append('profile_id', arr.UserData._id);
        formData.append('contentID', video._id);
        formData.append('lang', localStorage.getItem('lang'));


        Axios.post(url,
            formData,
        ).then(response2 => {
            if (response2.data.status == true) {
                this.setState({
                    popupshow: video,
                    showPopup: false,
                    showPopup2: true,
                    favSt: true
                })
            }
            else {
                this.setState({
                    popupshow: video,
                    showPopup: false,
                    showPopup2: true,
                    favSt: false
                })
            }
        })


    }
    createPlaylistMethod() {
        // alert(this.state.namePlayListNew)
        let arrayProfile = JSON.parse(localStorage.getItem('Profiles'));
        let mainProfile = JSON.parse(localStorage.getItem('mainProfile'));

        var arr = JSON.parse(localStorage.getItem('token'));
        let formData = new FormData();
        formData.append('access_token', arr.access_token);
        formData.append('name', this.state.namePlayListNew);
        formData.append('profileId', mainProfile._id);

        formData.append('lang', localStorage.getItem('lang'));
        Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/create",
            formData,
        ).then(response => {
            if (response.data.booleanValue) {
                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'playlist/' + response.data.rr.insertedId + '/edit');
            }
        })



    }

    render() {

        return (
            <div>
                <section id="Search" >

                    <div className="header">
                        <div className="leftSec">
                            <img className="img pointer" src="https://royakids.tv/websitedemo/logo.png" onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} />

                            <img id="backbtn" onClick={() => {
                                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home')
                            }} src={require("../assets/img/Group 4877.png")} />
                            <div className="SearchTitle">{this.state.lang['HeaderTitles'].titleMyPlaylists}</div>
                        </div>
                        <div className="centerSec">
                            <div className={this.state.type == "all" ? "all activeAll" : "all"}
                                onClick={() => {

                                    this.changeType('all')

                                }}
                            >
                                <div className="typetext">{this.state.lang['PlaylistScreen'].all}</div>

                            </div>
                            <div className={this.state.type == "songs" ? "Songs activeSongs" : "Songs"}
                                onClick={() => {
                                    this.changeType('songs')
                                }}
                            >
                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelSongsCount}</div>
                            </div>
                            <div className={this.state.type == "stories" ? "Stories activeStories" : "Stories"}
                                onClick={() => {
                                    this.changeType('stories')

                                }}
                            >
                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelStoriesCount}</div>

                            </div>
                            <div className={this.state.type == "shows" ? "Shows activeShows" : "Shows"}
                                onClick={() => {

                                    this.changeType('shows')

                                }}
                            >

                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelShowsCount}</div>
                            </div>
                        </div>
                        <div className="rightSec">
                            <div>
                                {/* <img src={require("../assets/img/logo.png")} /> */}
                                <Tooltip TransitionComponent={Zoom} title={localStorage.getItem('lang') === 'Ar' ? "حسابي" : "My account"} aria-label={localStorage.getItem('lang') == 'Ar' ? "حسابي" : "My account"} arrow>
                                    <div>
                                        <img src={localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' ? localStorage.getItem('minImg') ? Base64.decode(localStorage.getItem('minImg')) : '' : require("../assets/img/Group 4967.png") : ''} onClick={() => {
                                            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'parents');
                                        }} />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                    </div>
                    <ActionContent show2={{ showPopup2: this.state.showPopup2, data2: this.state.popupshow, favSt: this.state.favSt, type: this.state.type }} />
                    <InfoConent show={{ showPopup: this.state.showPopup, data: this.state.popupshow, tags: this.state.tags, type: this.state.type }} />
                    <div className="bodyHome">
                        <section id="CreatePlaylist"

                            style={{ visibility: this.state.createPlaylist ? 'visible' : 'hidden', opacity: this.state.createPlaylist ? '1' : '0', transition: 'opacity 2.5' }}

                        >
                            <div className="colseBtn"
                                onClick={() => {
                                    this.setState({
                                        createPlaylist: !this.state.createPlaylist
                                    })
                                }}
                            >X</div>
                            <div className="boxPopup">

                                <div className="contentPopup">
                                    <div className="titlecontentPopup">{this.state.lang['CreatePlaylistNamePopup'].title}</div>

                                </div>
                                <div className="descriptionPopup">
                                    <div className="descriptionPopup">
                                        <div className="input">
                                            <input placeholder={this.state.lang['CreatePlaylistNamePopup'].inputTxtLabel}
                                                onChange={(e) => {
                                                    this.setState({
                                                        namePlayListNew: e.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="btn" onClick={() => {
                                            this.createPlaylistMethod()
                                        }}>{this.state.lang['CreatePlaylistNamePopup'].ButtonCreateLabel}</div>
                                    </div>
                                </div>


                            </div>


                        </section>
                        <div className="Result">
                            <svg width="362.977" height="251.816" viewBox="0 0 362.977 251.816" style={{zIndex:'9999'}}
                                onClick={() => {
                                    this.setState({
                                        createPlaylist: !this.state.createPlaylist
                                    })
                                }}
                            >
                                <defs>
                                    <filter id="Rectangle_1089" x="0" y="0" width="362.977" height="251.816" filterUnits="userSpaceOnUse">
                                        <feOffset dy="16" input="SourceAlpha" />
                                        <feGaussianBlur stdDeviation="13" result="blur" />
                                        <feFlood floodColor="#634fc3" floodOpacity="0.141" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                    </filter>
                                </defs>
                                <g id="Group_1808" data-name="Group 1808" transform="translate(-188.572 -386.962)">
                                    <g transform="matrix(1, 0, 0, 1, 188.57, 386.96)" filter="url(#Rectangle_1089)">
                                        <rect id="Rectangle_1089-2" data-name="Rectangle 1089" width="284.977" height="173.816" rx="18" transform="translate(39 23)" fill="#ffd145" />
                                    </g>
                                    <text id="Create_Playlist" data-name="Create Playlist" transform={localStorage.getItem('lang') == 'Ar' ? "translate(580 556)" : "translate(366 556)"} fill="#fff" fontSize="29"  ><tspan x="-89.563" y="0">{this.state.lang['CreatePlaylistNamePopup'].title}</tspan></text>
                                    <g id="Group_1472" data-name="Group 1472" transform="translate(337.407 453.411)">
                                        <line id="Line_33" data-name="Line 33" x2="24.239" transform="translate(40.48 41.521)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
                                        <line id="Line_32" data-name="Line 32" y2="24.237" transform="translate(52.601 29.402)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
                                        <line id="Line_43" data-name="Line 43" x1="52.334" transform="translate(0 0)" fill="#4dda63" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
                                        <line id="Line_44" data-name="Line 44" x1="52.334" transform="translate(0 16.293)" fill="#4dda63" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
                                        <line id="Line_45" data-name="Line 45" x1="29.37" transform="translate(0 32.585)" fill="#4dda63" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
                                        <line id="Line_46" data-name="Line 46" x1="23.845" transform="translate(0 48.878)" fill="#4dda63" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
                                    </g>
                                    <path id="Path_2401" data-name="Path 2401" d="M0,0H230.809" transform="translate(484.96 594.388) rotate(180)" fill="none" stroke="#ffd145" strokeLinecap="round" strokeWidth="6" />
                                    <path id="Path_2402" data-name="Path 2402" d="M0,0H202.487" transform="translate(470.798 608.536) rotate(180)" fill="none" stroke="#ffd145" strokeLinecap="round" strokeWidth="6" />
                                </g>
                            </svg>

                            {
                                this.state.items.map((video, index) =>
                                    <div key={index}
                                        style={{ display: (video.showsImg.length + video.songsImg.length + video.storiesImg.length) != 0 ? 'block' : 'none' }}
                                    >
                                        {/* {video.showsImg.length}
                                        {video.songsImg.length}
                                        {video.storiesImg.length} */}
                                        <div key={video._id} className="item2"
                                            onClick={() => {
                                                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'playlist/' + video._id);
                                            }}
                                        >
                                            <div className="imgsList">
                                                {video.songsImg[0] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + video.songsImg[0]} /> : ''}
                                                {video.songsImg[1] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + video.songsImg[1]} /> : ''}
                                                {video.showsImg[0] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + video.showsImg[0]} /> : ''}
                                                {video.storiesImg[0] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + video.storiesImg[0]} /> : ''}

                                            </div>
                                            <div className="titleitem">{video.namePlayList}</div>
                                        </div>

                                    </div>





                                )

                            }
                        </div>
                    </div>
                </section>


            </div >
        );
    }
}

export default Search;